
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import HRMService from "../../../services/hrm-service";
import BuiltinService from "../../../services/buitin-service";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "../../../services/filter-service";
import Moment from 'moment'

@Component({
  components: {
    Header,
    Sidebar,
    Loader,
    FilterComponent
  },
})
export default class Passport extends Vue {
  loading = true;
  public filter = null;
  public filterOn = [];
  salary: any = {};
  public fields = [
    { key: "employee_title", sortable: false },
    { key: "passport_num", sortable: false },
    { key: "passport_issue_place", sortable: false },
    { key: "issue_date", sortable: true },
    { key: "expiry_date", sortable: true },
    {
      key: "created_at",
      sortable: true,
      formatter: (value: any) => {
        if (value) {
          const dateTime = new Date(value);
          const formattedDate = dateTime.toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-'); // Format as dd-mm-yyyy
          const formattedTime = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }); // Format as HH:MM:SS
          return `${formattedDate} / ${formattedTime}`;
        }
        return "";
      },
    },
    { key: "comments", sortable: false },


  ];
  public columns = [
    { label: 'Title', field: 'employee_title' },
    { label: 'Passport Number', field: 'passport_num' },
    { label: 'Passport Issue Place', field: 'passport_issue_place' },
    { label: 'Issue Date', field: 'issue_date' },
    { label: 'Expiry Date', field: 'expiry_date' },
    {
      label: 'Created At', field: 'created_at',
      format: (value: any) => {
        const date = new Date(value);
        const formattedDate = date.toLocaleDateString();
        const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
        return `${formattedDate} / ${formattedTime}`;
      }
    },
    { label: 'Comments', field: 'comments' },
  ];
  public widths = [80, 55, 50, 55, 55, 80, 80,]

  public selected_expat = "all";
  public option_expat = [
    { text: "All Employees", value: "all" },
    { text: "Saudi", value: "saudi" },
    { text: "Non Saudi", value: "Non saudi" },
  ];

  public option_dept: any = [];
  public selected_dept = 0;
  public option_emptype: any = [];
  public selected_emptype = 0;
  public option_branch: any = [];
  public selected_branch = 0;
  public option_bloodgroup: any = [];
  public selected_bloodgroup = 0;
  public selected_effective_date_from = "";
  public selected_effective_date_to = "";
  import_response = '';
  public selected_items = [];
  public items = [];
  public currentPage = 1;
  get rows() {
    return this.selected_items.length;
  }

  public import_file = null;
  edit = false;

  retrieve() {
    this.loading = true;
    HRMService.getAll_passports()
      .then((response) => {
        this.items = response.data;
        this.selected_items = this.items;
        // console.log(response.data)
        this.loading = false;
        // console.log(response.data);
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }

  refreshList() {
    this.get_filters();
    this.retrieve();
  }

  expatchange() {
    if (this.selected_expat == "all") {
      this.selected_items = this.items;
    } else {
      this.selected_items = this.items.filter(
        (x) => x["expats"] == this.selected_expat
      );
    }
  }

  bloodgroupchange() {
    if (this.selected_bloodgroup == 0) {
      this.selected_items = this.items;
    } else {
      let selectedoption = this.option_bloodgroup.filter(
        (y: any) => y["value"] == this.selected_bloodgroup
      );
      this.selected_items = this.items.filter(
        (x) => x["blood_group"] == selectedoption[0]["text"]
      );
    }
  }

  effectivedateto() {
    // console.log(this.selected_effective_date_to)
    if (this.selected_effective_date_to) {
      this.selected_items = this.items.filter(
        (x) => x["employment_effective_date"] < this.selected_effective_date_to
      );
    }
  }
  effectivedatefrom() {
    if (this.selected_effective_date_from) {
      this.selected_items = this.items.filter(
        (x) =>
          x["employment_effective_date"] < this.selected_effective_date_from
      );
    }
  }

  onFiltered(filteredItems: any) { }

  mounted() {
    this.retrieve();
    this.get_filters();
  }

  get_filters() {
    this.loading = true;
    BuiltinService.getallbuiltin()
      .then((response) => {
        this.option_dept = [];
        let dept: any = response.data["department"];
        this.option_dept = dept.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_dept.push({ value: 0, text: "Please select an option" });

        this.option_bloodgroup = [];
        let bloodgroup = response.data["blood_group"];
        this.option_bloodgroup = bloodgroup.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_bloodgroup.push({
          value: 0,
          text: "Please select an option",
        });

        this.option_emptype = [];
        let employment_type = response.data["employment_type"];
        this.option_emptype = employment_type.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_emptype.push({ value: 0, text: "Please select an option" });

        this.option_branch = [];
        let branch = response.data["branch"];
        this.option_branch = branch.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_branch.push({ value: 0, text: "Please select an option" });
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
      });
  }


  // onRowClicked(item: any, index: any, event: any) {
  //   this.$router.push({
  //     name: "Create-Salary",
  //     params: { id: item.id.toString() },
  //   });
  // }


  filter_fields = [
    { type: "text", label: "Employee Title", name: "title", value: "", param_name: "passport_title", filter_type: "simple" },
    { type: "text", label: "Passport Number", name: "passport_num", value: "", param_name: "passport_num", filter_type: "simple" },
    { type: "text", label: "Passport Issue Place", name: "passport_issue_place", value: "", param_name: "passport_issue_place", filter_type: "simple" },
    { type: "date", label: "Issue Date", name: "passport_issue_date", value: "", param_name: "issue_date_pasport", filter_type: "simple" },
    { type: "date", label: "Expiry Date", name: "passport_expiry_date", value: "", param_name: "expiry_date_passport", filter_type: "simple" },
    { type: "date", label: "Created At", name: "passport_created_at", value: "", param_name: "passport_created_at", filter_type: "simple" },

  ];

  advance_filter_fields = [
    { type: "date", label: "Issue Date", min_param_name: "min_issue_date_passport", max_param_name: "max_issue_date_passport", start: "", end: "", range: [], filter_type: "advance" },
    { type: "date", label: "Expiry Date", min_param_name: "min_expiry_date_iqama", max_param_name: "max_expiry_date_iqama", start: "", end: "", range: [], filter_type: "advance" },
  ];
  handleFilterChange(element: any) {
    if (element.filter_type === "simple") {
      interface SimpleObj {
        [key: string]: any;
      }
      const simpleFilteredObj: SimpleObj = {};
      this.filter_fields.forEach(filter => {
        simpleFilteredObj[filter.param_name] = filter.value;
      });
      this.loading = true;
      FilterService.getAll(simpleFilteredObj).then((data) => {
        if (data.data.employees_passport !== undefined && data.data.employees_passport !== null) {
          this.selected_items = (data.data.employees_passport)
          this.loading = false;
        }
        else {
          this.retrieve()
        }
      })
    }
    else if (element.filter_type === "advance") {
      interface AdvanceObj {
        [key: string]: any;
      }
      const advanceFilteredObj: AdvanceObj = {};
      this.advance_filter_fields.forEach(filter => {
        if (filter.type === "date") {
          if (filter.min_param_name && filter.range) {
            const date0 = new Date(filter.range[0]);
            const formattedDate0 = Moment(date0).format('YYYY-MM-DD');
            advanceFilteredObj[filter.min_param_name] = formattedDate0 === "Invalid date" ? '' : formattedDate0
          }
          if (filter.max_param_name && filter.range) {
            const date1 = new Date(filter.range[1]);
            const formattedDate1 = Moment(date1).format('YYYY-MM-DD');
            advanceFilteredObj[filter.max_param_name] = formattedDate1 === "Invalid date" ? '' : formattedDate1
          }
        }
      });
      this.loading = true;
      FilterService.getAll(advanceFilteredObj).then((data) => {
        if (data.data.employees_passport !== undefined && data.data.employees_passport !== null) {
          this.selected_items = (data.data.employees_passport)
          this.loading = false;
        }
        else {
          this.retrieve()
        }
      })
    }
  }
  updateFilterFields(fields: any[]) {
    this.filter_fields = fields;
  }
  refreshDateFeild(element: any) {
    // this.retrieve();
    element.range = [];

  }
  refreshFilters() {
    for (let field of this.filter_fields) {
      field.value = "";
    }
    this.retrieve();
  }
  refreshAdvanceFields() {
    for (let field of this.advance_filter_fields) {
       if(field.type === "date"){
        field.range = [];
        field.start = '';
        field.end= '';
      }
      this.retrieve();
    }
  }

}
