import { render, staticRenderFns } from "./passport.vue?vue&type=template&id=78d6f525"
import script from "./passport.vue?vue&type=script&lang=ts"
export * from "./passport.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports